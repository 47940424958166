import React from "react";
import { useAppSelector } from "hooks/reduxHooks";
import { Navigate, useLocation } from "react-router-dom";
import { getAccountInfo, getProfileID } from "services/localStorage.service";
interface Props {
  children: React.ReactNode;
}

const RequireAuth: React.FC<Props> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);
  let profileId: number = getProfileID();
  const clientProfileId: number = profileId;
  const location = useLocation();
  const scope = getAccountInfo()?.scopes;

  // Define the allowed profile IDs for each screen
  const allowedProfilesByScreen: Record<string, number[]> = {
    '/dashboard': [2,3,4,9],
    '/report': [2,6,9],
    '/client': [2,9],
    '/routes-and-work-schedule': [2,3,4],
    '/users': [2],
    '/agreement': [2],
    '/professional': [2,9],
    '/orders': [3,5],
    '/shipment-document': [3,5,7],
    '/affiliation': [3,5],
    '/warehouse': [6],
    '/incoming-soil': [8],
  };
  
  // Get the current location from the navigate hook
  const currentLocation = location.pathname;
  if(profileId === 0) profileId = 9; // if profileId is empty then it would be client user the id is 9
  // Check if the current profile ID is allowed for the current screen
  const isProfileAllowed = profileId === 1 || Object.keys(allowedProfilesByScreen).some((allowedRoute) => {
    return currentLocation.startsWith(allowedRoute) && allowedProfilesByScreen[allowedRoute]?.includes(profileId);
  });
  // Check if the current scope is allowed for the current screen
  const isScopeAllowed = scope === "Dashboard" && clientProfileId === 0 && currentLocation === "/report-v2/list"
  if (isScopeAllowed) return <Navigate to="/dashboard" replace />;
  
  return (token && isProfileAllowed) ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
