import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Modal } from "antd";
import {
  sidebarNavigation,
  sidebarNavigation2,
} from "./sidebarNavigation";
import Sider from "antd/lib/layout/Sider";
import "./Sidebar.css";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import UserImg from '../../icons/user.png'
import { doSetClient } from "store/slices/authSlice";

import {
  getAccountInfo,
  getClient,
  getProfileID,
  isAccount,
} from "services/localStorage.service";
import { notificationController } from "controllers/notificationController";
import { doGetClient } from "store/slices/clientSlice";
import ClientListMenu from "components/client/clientListMenu/ClientListMenu";
import ShipmentListMenuShared from "components/common/ShipmentListMenuShared/ShipmentListMenuShared";
import { capitalize } from "lodash";
interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const clientList = useAppSelector((state) => state.client.clientList);
  const sideBarWidth = "280px";
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accountLevel = isAccount();
  let profileID: number = getProfileID();
  const clientProfileId: number = profileID;
  if (profileID === 0) profileID = 9;
  const sidebarNavigations = sidebarNavigation2[profileID]
  const client = getClient();
  const { pathname } = window.location;
  const path = pathname.split("/")
  const isClientPage = path[1] === 'client'
  const isShipmentPage = path[1] === 'shipment-document' && path[2] === 'details'
  const isShipmentPageV2 = path[1] === 'shipment-document-v2' && path[2] === 'details'
  const isShipmentPageV3 = path[1] === 'shipment-document-v3' && path[2] === 'details'
  const [selectedMenuItem, setSelectedMenuItem] =  useState<string[]>([])
  const [clientId, setClientId] = useState<any>(null);
  const { UserFullName: userName, scopes: scope } = getAccountInfo() || {};
  useEffect(()=>{
    handleSetSelectedMenuItem()
  },[pathname])

  const handleSetSelectedMenuItem = () => {
    const currentNavigations = !accountLevel && !sidebarNavigations?.length && client ? sidebarNavigation : sidebarNavigations;
    const rootPath = pathname.split("/")[1];

    const currentPathMenuItem = currentNavigations?.find((navItem)=> {
      return navItem.url?.split("/")[1] === rootPath
    }) 
    setSelectedMenuItem([currentPathMenuItem?.key || ""])
  }

  useEffect(() => {
    dispatch(doGetClient());
  }, []);

  useEffect(() => {
    setClientId(
      getAccountInfo() && getAccountInfo().ClientID
        ? getAccountInfo().ClientID
        : null
    );
  }, []);

  useEffect(() => {
    setClientId(localStorage.getItem("client_Id"))
  },[localStorage.getItem("client_Id")])

  const onChangeCompany = (selectedValue: any, firstTime = false) => {
    localStorage.setItem("client_Id", selectedValue)
    dispatch(doSetClient({ client_id: selectedValue }))
      .unwrap()
      .then(() => {
        setClientId(selectedValue);
        // navigate(`/client/list`);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        //  setLoading(false);
      });
  };

  const filterData = (data: any) => {
    const filterData = data?.filter((v: any) => v.is_client_deleted === false &&
      v.company_name !== null )

    let newArray: any = [];

    if (data) {
      filterData?.map((item: any) => {
        if (item) {
          newArray.push({
            label: item.company_name,
            value: item.id,
            key: item.id,
          });
        }
      });
      if (!getAccountInfo() || !getAccountInfo().ClientID) {
        if (newArray.length > 0) {
          onChangeCompany(newArray[0].value, true);
        }
      }
    }

    return newArray;
  };

  return (
    <div className="flex">
      <div className="side-bar">
        <Sider className="sider" width={sideBarWidth} collapsible={false}>
          <div className="user-info">
            <div className="flex">
              <img width={53} height={53} src={UserImg} alt="user" />
              <div className="pl-4">
                <p className="user-name">{capitalize(userName)}</p>
                <Link to='logout' className="sign-out-text">Sign Out</Link>
              </div>
            </div>
          </div>
          <div className="nav-bar">
            {accountLevel ? (
              <>
                {!client ? (
                  <Modal
                    title="Company"
                    footer={null}
                    visible={visible}
                    onCancel={() => {
                      setVisible(false);
                    }}
                  >
                    <div style={{ height: "50px" }}>Please select company.</div>
                  </Modal>
                ) : (
                  ""
                )}
                <Menu
                  theme="dark"
                  mode="inline"
                  className="btm"
                  selectedKeys={selectedMenuItem}
                >
                  {sidebarNavigations?.map((nav: any, index: number) => (
                    <Menu.Item key={nav.key} title="" icon={nav.icon}>
                      <img width={34} style={{ padding: '0 8px 4px 0' }} src={nav.svgIcon} alt="svg" />
                      <Link to={nav.url || ""}>{nav.title}</Link>
                    </Menu.Item>
                  ))}
                </Menu>
              </>
            ) : (
              ""
            )}
            {(clientList || profileID === 9) &&
              <>
                <div className="overlay-wrapper">
                  {accountLevel && !client ? (
                    <div
                      className="overlay"
                      onClick={() => {
                        setVisible(true);
                      }}
                    >
                    </div>
                  ) : (
                    ""
                  )}
                <Menu
                  theme="dark"
                  className="btm-menu"
                  selectedKeys={selectedMenuItem}
                  mode="inline"
                >
                  {!accountLevel
                    ? sidebarNavigation.map((nav: any, index: number) => {

                      if (clientProfileId === 0 && scope === "Dashboard"  && nav.key === "report") return null;   
                      return nav.children && nav.children.length > 0 ? (
                        <Menu.SubMenu
                          key={nav.key}
                          title={nav.title}
                          icon={nav.icon}
                          onTitleClick={() => setCollapsed(false)}
                          popupClassName="d-none"
                        >
                        </Menu.SubMenu>
                      ) : (
                        <Menu.Item key={nav.key} title="" icon={nav.icon}>
                          <img width={34} style={{ padding: '0 8px 4px 0' }} src={nav.svgIcon} />
                          <Link to={nav.url || ""}>{nav.title}</Link>
                        </Menu.Item>
                      )
                    })
                    : ""}
                </Menu>                  
                </div>
              </>
            }
          </div>
          <div className="log-out">
            <img src="/logo.png" alt="logo" />
          </div>
        </Sider >
      </div>
      {((isClientPage || isShipmentPage || isShipmentPageV2 || isShipmentPageV3) &&
        (accountLevel && ((profileID == 1 || profileID == 2 || profileID == 7)))) &&
        <div className="nested-sidebar">
          <Sider className="nested-sider" width={sideBarWidth} collapsible={false}>
            {isClientPage ?
              <ClientListMenu filterData={filterData} onChangeCompany={onChangeCompany} clientId={clientId} />
              : <ShipmentListMenuShared isShipmentPageV2={isShipmentPageV2 && isShipmentPageV2} isShipmentPageV3={isShipmentPageV3 && isShipmentPageV3}/>}
          </Sider>
        </div>}
    </div>
  );
};

export default SiderMenu;
